<template>
    <div>
        <CommentsModal :visible="commentsModalVisible" :source-id="$route.params.invoiceId" @refresh="getComments" @close="commentsModalVisible = false" />
        <PayModal :visible="payModalVisible" :source-id="$route.params.invoiceId" @close="payModalVisible = false" />
        <RefundModal :visible="refundModalVisible" :source-id="$route.params.invoiceId" @close="refundModalVisible = false" />
        <div class="card-tail">
            <TopMenu
                @addComment="showAddCommentModal"
                @addPayment="showPaymentModal"
                @makeRefund="showRefundModal"
                :invoice-type="invoiceData.typeName"
                :balance="amountLeft"
                :currency="invoiceData.currencyCode"
                :is-eligible-for-factoring="invoiceData.isEligibleForFactoring"
            />
        </div>
        <div class="grid grid-cols-12 gap-3">
            <InvoiceContent :invoice-data="invoiceData" v-loading="$waiting.is('loading')" />

            <div class="col-span-4">
                <Comments ref="commentsComponent" />
            </div>
            <div class="col-span-4">
                <Events :events-data="invoiceData.events" v-loading="$waiting.is('loading')" />
                <Ledger :ledger-data="invoiceData.ledger" v-loading="$waiting.is('loading')" />
                <AdminNote v-if="invoiceData.comment" :note-data="invoiceData.comment" v-loading="$waiting.is('loading')" />
            </div>
        </div>
    </div>
</template>
<script>
import Api from "./invoices.api";

export default {
    components: {
        Events: () => import(/* webpackChunkName: "InvoiceDetailsEvents" */ "./components_details/Events.vue"),
        Comments: () => import(/* webpackChunkName: "InvoiceDetailsComments" */ "./components_details/Comments.vue"),
        Ledger: () => import(/* webpackChunkName: "InvoiceDetailsLedger" */ "./components_details/Ledger.vue"),
        AdminNote: () => import(/* webpackChunkName: "InvoiceDetailsAdminNote" */ "./components_details/AdminNote.vue"),
        InvoiceContent: () => import(/* webpackChunkName: "InvoiceDetailsInvoiceContent" */ "./components_details/InvoiceContent.vue"),
        TopMenu: () => import(/* webpackChunkName: "InvoiceDetailsTopMenu" */ "./components_details/TopMenu.vue"),
        CommentsModal: () => import(/* webpackChunkName: "InvoiceDetailsCommentsModal" */ "./modals/InvoiceDetailsCommentsModal.vue"),
        PayModal: () => import(/* webpackChunkName: "InvoicePayModal" */ "./modals/InvoicePayModal.vue"),
        RefundModal: () => import(/* webpackChunkName: "InvoicePayModal" */ "./modals/InvoiceRefundModal.vue"),
    },

    data() {
        return {
            invoiceData: {},
            commentsModalVisible: false,
            payModalVisible: false,
            refundModalVisible: false,
        };
    },

    created() {
        this.getDetails();
    },

    methods: {
        async getDetails() {
            this.$waiting.start("loading");
            this.invoiceData = await Api.getDetails(this.$route.params.invoiceId);
            this.$waiting.end("loading");
            document.title = "Faktura - " + this.invoiceData.clientName;
        },

        getComments() {
            this.$refs.commentsComponent.getComments();
        },

        showAddCommentModal() {
            this.commentsModalVisible = true;
        },

        showPaymentModal() {
            this.payModalVisible = true;
        },

        showRefundModal() {
            this.refundModalVisible = true;
        },
    },
    computed: {
        amountLeft() {
            let balance = 0;
            if (this.invoiceData.ledger != undefined) {
                this.invoiceData.ledger.forEach(item => {
                    balance += item.amount;
                });
            }
            return balance;
        },
    },
};
</script>
