import Vue from "vue";

export default {
    getAll: async (pageNumber, pageSize, search, sortBy, sortByType, status, currency, dateFrom, dateTo, clientId) => {
        try {
            const response = await Vue.axios.get(`invoices`, {
                params: {
                    pageNumber,
                    pageSize,
                    search,
                    sortBy,
                    sortType: sortByType,
                    status,
                    currency,
                    startDate: dateFrom,
                    endDate: dateTo,
                    clientId,
                },
            });
            return response;
        } catch (error) {
            console.log("🚀 ~ file: invoices.api.js ~ getAll: ~ error", error);
            return [];
        }
    },

    getCounts: async (search, currency, clientId, startDate, endDate) => {
        try {
            const { data } = await Vue.axios.get(`invoices/counts`, {
                params: {
                    search,
                    currency,
                    startDate,
                    endDate,
                    clientId,
                },
            });
            return data;
        } catch (error) {
            console.log("🚀 ~ file: invoices.api.js ~ getCounts: ~ error", error);
            return [];
        }
    },

    getDetails: async invoiceId => {
        try {
            const { data } = await Vue.axios.get(`invoices/${invoiceId}`);
            return data;
        } catch (error) {
            console.log("🚀 ~ file: invoices.api.js ~ getDetails: ~ error", error);
            return [];
        }
    },

    getClients: async () => {
        try {
            const response = await Vue.axios.get(`invoices/clients`);
            return response.data;
        } catch (error) {
            console.log("🚀 ~ file: invoices.api.js ~ getClients: ~ error", error);
            return [];
        }
    },

    getCommentsBySourceId: async id => {
        try {
            const { data } = await Vue.axios.get(`invoices/comments`, {
                params: {
                    commentSource: 1,
                    sourceId: id,
                },
            });
            return data;
        } catch (error) {
            console.log("🚀 ~ file: invoices.api.js ~ getCommentsBySourceId: ~ error", error);
            return [];
        }
    },

    addComment: async (sourceId, comment) => {
        try {
            await Vue.axios.post(`comments`, {
                commentSource: 1,
                sourceId,
                text: comment,
            });
        } catch (error) {
            console.log("🚀 ~ file: invoices.api.js ~ addComment: ~ error", error);
        }
    },

    getPaymentValues: async invoiceId => {
        try {
            const { data } = await Vue.axios.get(`invoices/${invoiceId}/paymentValues`);
            return data;
        } catch (error) {
            console.log("🚀 ~ file: invoices.api.js ~ getPaymentValues ~ error", error);
            return [];
        }
    },

    registerPayment: async (invoiceId, paymentObject) => {
        try {
            await Vue.axios.post(`invoices/${invoiceId}/payment`, paymentObject);
        } catch (error) {
            console.log("🚀 ~ file: invoices.api.js ~ registerPayment ~ error", error);
        }
    },

    movePayment: async (invoiceId, paymentObject) => {
        try {
            await Vue.axios.post(`invoices/${invoiceId}/payment/move`, paymentObject);
        } catch (error) {
            console.log("🚀 ~ file: invoices.api.js ~ movePayment ~ error", error);
        }
    },

    createRefund: async (invoiceId, refundObject) => {
        try {
            await Vue.axios.post(`invoices/${invoiceId}/refund`, refundObject);
        } catch (error) {
            console.log("🚀 ~ file: invoices.api.js ~ refund ~ error", error);
        }
    },

    getAccountTypes: async () => {
        try {
            const { data } = await Vue.axios.get(`invoices/accountTypes`);
            return data;
        } catch (error) {
            console.log("🚀 ~ file: invoices.api.js ~ getAccountTypes: ~ error", error);
            return [];
        }
    },
};
